<template>
  <div class="layout-wrapper">
    <TheHeader />

    <div class="merchandise-header has-text-centered" />

    <div class="af-container">
      <slot />
    </div>

    <div class="af-footer">
      <TheFooter />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppLayoutCheckout",
  components: {
    TheHeader: () => import("@/components/TheHeader"),
    TheFooter: () => import("@/components/TheFooter"),
  },
  computed: {
    ...mapGetters({
      maxTime: "preliminaryAssessment/maxTime",
    }),
  },
};
</script>

<style scoped lang="scss">
.layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  flex-basis: 0;
  flex-direction: column;

  .merchandise-header {
    background-image: url("../assets/images/background-sport-car.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 11.5rem;
  }

  .af-container {
    flex-grow: 1;
    width: 100%;
    margin: 0 auto;
  }

  .af-footer {
    width: 100%;
    display: flex;
  }
}

@media only screen and (max-width: 769px) {
  .layout-wrapper {
    .merchandise-header {
      height: 5rem;
    }

    .af-footer {
      display: none;
    }
  }
}
</style>
